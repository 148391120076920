import { useStaticQuery, graphql } from "gatsby"
export const usePartnersData = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query PartnerQuery {
        wordpress {
          page(idType: URI, id: "partners"){
            partnersTitleMeta {
              title
              sectionBgColour
              hubspotTitle
              hubspotText
              hubspotCode
            }
          }
          partnerTypes(first: 50, where: {orderby: TERM_ORDER, order: ASC}) {
            nodes {
              name
              slug
              description
              contentNodes(first: 100)  {
                nodes {
                  id
                  slug
                  ... on WORDPRESS_Partner {
                    id
                    uri
                    title(format: RENDERED)
                    partnerInformation {
                      partnerLevel
                      showReadMore
                    }
                    featuredImage {
                      node {
                        altText
                        srcSet
                        sourceUrl
                        imageFile {
                          publicURL
                          extension
                          childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED)
                            fluid(maxWidth: 1200) {
                              base64
                              aspectRatio
                              src
                              srcSet
                              srcWebp
                              srcSetWebp
                              sizes
                              presentationWidth
                            }            
                            original {
                              src
                            }
                          }
                        }
                      }
                    }
                    ExternalURL {
                      externalUrl
                    }
                    metaContent {
                      loopDescription
                    }
                  }                  
                }
              }
            }
          }
        }
      }
    `
  )
  return wordpress
}
