import React from "react"
import Base from "./Base"

// import { Helmet } from "react-helmet";

import PartnersPage from "../Partners/PartnersPage";

const PartnersPageTemplate = ({ pageContext }) =>  {
    
  return (
    <Base context={pageContext}>
      <PartnersPage data={pageContext} />
    </Base>
  )
}

export default PartnersPageTemplate