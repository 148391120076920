import React, { useEffect, useState } from "react"

import styled from "styled-components"

import { usePartnersData } from "../hooks/usePartnersData";

import ThemeDefault from "../theme/ThemeDefault";

import SwiperCore, { Navigation, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import LeftArrow from '../../assets/images/icons/left-arrow.svg'
import RightArrow from '../../assets/images/icons/right-arrow.svg'
import { Link } from "gatsby";
import LoadedImage from "../helpers/LoadedImage";

import {ParseHTML} from "../helpers/ParseHTML"

import Medal from '../../assets/images/icons/medal.svg'
import { isBrowser } from "../helpers/helpers"

SwiperCore.use([Navigation, Mousewheel]);

const PartnerPageSection = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`

  padding: 0;
  
  .loadedCardWrapper {
    height: 100% !important;
  }
  
  .inner-dev {
    max-width: 1200px;
    margin: 0 auto;
    width: 100% !important;

  }

  .no-padding {
    padding: 0;
    width: 100% !important
  }
  .swiper-slide,
  .swiper-container {
    overflow: visible !important;
    height: auto;
  }
  .swiper-slide {
    height: auto !important;
  }
  /* background-color: ${ThemeDefault.offWhite}; */
  .slider-wrapper {
    width: 100%;
    overflow: hidden !important; 
    flex-direction: row !important;
    align-items: stretch !important;


      & .swiper-wrapper {
        flex-direction: row !important;
        align-items: stretch !important;
        ${({ isCentered }) => isCentered && `justify-content: center;`}
      }

  }
  
  .medal-tag {

    display: inline-flex;
    position: absolute;
    top: 16px;
    left: 16px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 8px 16px;
    border-radius: 24px;
    box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.4);

    svg {
      width: 16px;
      margin-right: 8px;
      height: auto;
    }

    &.gold {
      background-color: #d6b80f;
      color: #fff;
    }

    &.silver {
      background-color: #a7a4a4;
      color: #fff;
    }

    &.platinum {
      background-color: #8a8a8a;
      color: #fff;
    }
  }

  .spacer {
    padding: ${ThemeDefault.responsivePadding};
    padding-left: 0;
    padding-top: 24px;
    padding-right: 0;
  }

  .arrow-row {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: justify;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 24px;
    padding: ${ThemeDefault.responsivePadding};
    padding-left: 0;
    padding-top: 24px;
    padding-right: 0;

    svg {
      pointer-events: none;
      padding: 1px;
    }
  }

  .short-image,
  .tall-image {
    width: 100%;
    padding-bottom: 150%;
    position: relative;
    top: 0px;
    left: 0px;
    /* max-width: 250px; */
    max-height: 375px;
    background: ${ThemeDefault.offWhite};

    & .gatsby-image-wrapper,
    & .gatsby-image-wrapper-constrained,
    & > img {
      max-height: 375px;
      position: absolute !important;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain !important;
      object-position: center;
      mix-blend-mode: multiply;

    }
    & .gatsby-image-wrapper-constrained img,
    & .gatsby-image-wrapper img {
      width: 80% !important;
      height: 80% !important;
      object-fit: contain !important;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  .card-wrap {
    display: flex;
    flex-direction: row;
  }

  .loadedCard {

    box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.4);
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 12px;

    & .tall-image,
    & .short-image {
      overflow: hidden;
    }

    & .responsive-image {
      left: 50%;
      top: 50%;
      transform: translate( -50%, -50%) scale(1);
      transition: 600ms;
      transition-timing-function: cubic-bezier(.49,.01,.51,1);
    }
    &:hover {
      & .responsive-image {
        transform: translate( -50%, -50%) scale(1.1);
      }
    }

    &.text-inside {
      & .card-title-wrapper {
        background-image: linear-gradient(rgba(22,20,20,0) 0%,rgba(22,20,20,0.92) 50%);
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 1;
        background-blend-mode: multiply;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        
        & .card-title {
          padding: 8px;
          padding-top: 24px;
          color: ${ThemeDefault.white};
          font-weight: 500;
          margin: 0;
          line-height: 1.2;
          font-family: ${ThemeDefault.fontPrimary};
          font-size: clamp(1.8rem, calc(1.8rem + ((1vw - 0.48rem) * 0.5)), 2.2rem);

          &.has-sub {
            padding-bottom: 0;
          }
        }

        & .sub-rows {
          padding: 0 8px 8px 8px;
          color: ${ThemeDefault.white};
          font-size: clamp(1rem, calc(1rem + ((1vw - 0.48rem) * 0.3922)), 1.4rem);
          line-height: 1.2;
        }
      }
    }
    &.text-outside {
      & .card-title-wrapper {
        position: relative;
        background: none;
        padding: 24px;
        padding-top: 24px;
        background: #fff;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        & .card-title {
          color: ${ThemeDefault.black};
          font-weight: 500;
          margin: 0;
          margin-top: 16px;
          line-height: 1.2;
          font-family: ${ThemeDefault.fontPrimary};
          font-size: clamp(1.6rem, calc(1.6rem + ((1vw - 0.48rem) * 0.1961)), 1.8rem);
        }       
      }
    }

    .loop-description {
      color: ${ThemeDefault.black};
      font-size: clamp(1.2rem, calc(1.2rem + ((1vw - 0.48rem) * 0.2941)), 1.5rem);
      margin-top: 4px;
      line-height: 1.4
    }

    .readMore {
      text-align: left;
      margin-top: 24px;
      font-size: clamp(1.2rem, calc(1.2rem + ((1vw - 0.48rem) * 0.2941)), 1.5rem);
      padding: 8px 16px; 
      border: 1px solid ${ThemeDefault.blue};
      display: inline-block;
    }
  }
  
  .short-image {
    padding-bottom: 75%;
  }

  .card-wrapper {
    
    display: flex;
    flex-direction: row;
  
    .loadedCardWrapper {
      margin: 0 12px;
      max-width: calc(1200px / 3.2);
      width: 100%;
      height: 100% !important;

      .loadedCard {
        max-width: calc(1200px / 3.2);
        width: 100%;
        
        
      }
    }
  }

  .slider-card {
    /* max-width: calc((1200px * 0.66) / 3.4); */
    /* margin-right: 16px; */
    max-width: calc(1200px / 3);;
  }
  
`

const SectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};

  .inner-dev {
    flex-direction: row;
    display: flex;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;  
  }

  h1 {
    font-size: ${ThemeDefault.font_6};
    line-height: 1.4;
    letter-spacing: -1px;
    margin: 32px 0;
    text-align: left;
    font-weight: 500;
    width: 100%;
    text-align: center;

    p {
      margin-bottom: 20px;
    }
  }

`

const PartnersPage = ( data ) => {
  
  const partnersData = usePartnersData();

  const [pageWidth, setPageWidth] = useState(isBrowser() ? window.innerWidth : 0)
  const [minSliderSlides, setMinSliderSlides] = useState(4)


  useEffect(() => {
    setPageWidth(isBrowser() ? window.innerWidth : 0)

    switch (true) {
      case (pageWidth >= 1200):
        setMinSliderSlides(3.2)
        break;
      case (pageWidth >= 992):
        setMinSliderSlides(3.5)
        break;
      case (pageWidth >= 768):
        setMinSliderSlides(2.4)
        break;
      case (pageWidth >= 480):
        setMinSliderSlides(1.2)
        break;
      default:
        setMinSliderSlides(1)
        break;
    }

  }, [])

  // console.log(data);
  // console.log(partnersData);

  return (
    <>
      <SectionComponent cssClass="m-top c-center" bgColour={partnersData.page.partnersTitleMeta.sectionBgColour} >
        <div className="inner-div wrapper">
          <div className="page-content">
            <h1>{ partnersData.page.partnersTitleMeta.title }</h1>
          </div>
        </div>
      </SectionComponent>
      {partnersData.partnerTypes.nodes.map((type, index) => {
        
        if (type.contentNodes.nodes.length > 0) {
          return (
            <PartnerPageSection cssClass="c-center" key={`partner-${index}`} isCentered={type.contentNodes.nodes.length < minSliderSlides} id={type.name.split(' ').join('-').replace('&', '').toLowerCase()}>
              <div className="inner-div wrapper">
                <h2 className="c-center">{type.name}</h2>  
                <div className="c-center">{type.description}</div>  
              </div>
              <div className="inner-div no-padding">
                <div className={`slider-wrapper`}>
                    <SwiperComponent slides={type.contentNodes.nodes} index={data.index} />
                </div>
              </div>
            </PartnerPageSection>
          )
        } else {
          return false;
        }
      })}
    </>
  );
}

export default PartnersPage

const SwiperComponent = (props) => {

  const { slides, index } = props;

  return (
    <>
      <Swiper
        freeMode={true}
        spaceBetween={0}
        // loop={true}
        slidesPerView={1.2}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        navigation={{
          nextEl: `.swiper-next-${index}`,
          prevEl: `.swiper-prev-${index}`,
        }}
        breakpoints={{
          "480": {
            "slidesPerView": 1.2
          },
          "768": {
            "slidesPerView": 2.4
          },
          "992": {
            "slidesPerView": 3.5
          },
          "1200": {
            "slidesPerView": 3.2
          }
        }}
        mousewheel={{
          forceToAxis: true,
          invert: false,
        }}
        keyboard={{
          enabled: true,
          onlyInViewport: true,
        }}
      >  
        {slides.map((slide, index) => (
          <SwiperSlide className={`slider-card ${slide.id}`} key={`card-${index}`}>
            <PartnerCard slide={slide} />
          </SwiperSlide>
        ))}
      </Swiper>
      {slides.length > 3 ?
      <div className="arrow-row">
        <ArrowWrap className={`swiper-prev-${index}`}>
          <LeftArrow  />
        </ArrowWrap>
        <ArrowWrap className={`swiper-next-${index}`}>
          <RightArrow  />
        </ArrowWrap>
      </div>
      :
      <div className="spacer">&nbsp;</div>
      }
    </>
  );

}

const ArrowWrap = styled.div`
  width: 40px;
  height: 40px;
  padding: 4px;
  cursor: pointer;
`

const PartnerCard = ( props ) => {
  const { slide } = props
  
  return (
    <>
      {
        (slide.partnerInformation.partnerLevel !== null && slide.partnerInformation.partnerLevel !== 'none') || 
        (slide.partnerInformation.partnerLevel === 'none' && slide.partnerInformation.showReadMore) ?
        <Link to={slide.uri} className="loadedCardWrapper">
          <CardInner slide={slide} />
        </Link>
        : 
        <div className="loadedCardWrapper">
          <CardInner slide={slide} />
        </div>
      }
    </>
  )
}

const CardInner = ({slide, cssClass}) => {

  return (
    <div className="loadedCard text-outside" >
      <div className={`short-image`}>
        {slide.featuredImage && <LoadedImage altText={slide.featuredImage.node.altText} imgData={slide.featuredImage.node.imageFile} className="responsive-image" />}
        {slide.partnerInformation.partnerLevel !== 'none' && slide.partnerInformation.partnerLevel !== null && 
          <div className={`medal-tag ${slide.partnerInformation.partnerLevel}`}>
            <Medal /> {slide.partnerInformation.partnerLevel} PARTNER
          </div>
        }
      </div>
      <div className="card-title-wrapper">
        <div className="description">
          {(slide.metaContent && (slide.metaContent.loopDescription !== null && slide.metaContent.loopDescription !== '')) && 
            <div className="loop-description">{slide.metaContent.loopDescription && ParseHTML(slide.metaContent.loopDescription)}</div>
          }  
        </div>
        { ((slide.partnerInformation.partnerLevel !== null && slide.partnerInformation.partnerLevel !== 'none') || 
          (slide.partnerInformation.partnerLevel === 'none' && slide.partnerInformation.showReadMore)) &&
          <div className="blue open">
              <div className="readMore">Read More &rarr;</div>          
          </div>
        }
      </div>
    </div>
  )
}